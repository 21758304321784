/// <reference path="../services/scheduleJob.services.ts" />
/// <reference path="../services/users.services.ts" />
class socketServiceClass {
    public namespace: string;
    private jwt: string;
    public socket: any;
    private user: socketuser;
    userServices:UsersServiceClass;
    constructor(namespace, jwt, $rootScope) {
        var me = this;
        this.namespace = namespace;
        this.jwt = jwt;
        this.userServices = new UsersServiceClass(this.socket,this.namespace)

        this.socket = io('/' + me.namespace ,  {reconnectionDelayMax: 20000 , timeout:20000})
            .on('connect', function () {
                $rootScope.$broadcast('ioconnected', '/' + me.namespace);
                if (me.jwt) {
                    me.settoken(me.jwt);
                }
            }).on('connecting', function () {
            }).on('disconnect', function () {
                $rootScope.$broadcast('iodisconnected', '/' + me.namespace);
            });
        this.socket.on('ADDLOGRECORD2', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on("GETPAGINGDETAILS", (...args) => {args[args.length - 1].apply(this, args);});      
        this.socket.on('QUERY', (...args) => { args[args.length - 1].apply(this, args); });        
        this.socket.on('POST', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('PUT', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('UPDATEPROPS', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('DELETE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('AGGREGATE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('INVOKE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('CREATEINSTANCE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('HASH', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('RECOVEREPASSWORD', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('RESETPASSWORD', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('CREATEEDITOR', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ENCRYPT', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('DECRYPT', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('INVOKEROBOT', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ONSTOPWORKFLOW', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('SAVEFILE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GETFILE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('DELETEFILE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GETUSERTOKEN', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_ROLES_PAGE', (...args) => { args[args.length - 1].apply(this, args); });

        this.socket.on('UPDATEUSER', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ADDUSER', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ADDUSERROLE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ADDSCHEDULEJOB', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ADDUPDATESCHEDULEJOB', (...args) => { args[args.length - 1].apply(this, args); });

        this.socket.on('UPDATESCHEDULESTATUS', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('DELETESCHEDULEJOB', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('DELETEPROJECTSANDWORKFLOWS', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GETPROJECTJSON', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('EXPORTPROJECT', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('IMPORTPROJECTJSON', (...args) => { args[args.length - 1].apply(this, args); });
        
        this.socket.on('UPDATEPROJECTSROLES', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('UPDATEWORKFLOWSROLES', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GETPROJECTSBACKUPS', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('SETBACKUPASCURRENT', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GETPROJECTSGROUPS', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('DELETE_BACKUP_PROJECTS', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_USERS_PERMITTED_ON_PROJECT', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_PROJECTS_PAGE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_Queues_PAGE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('DELETE_QUEUE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_WORKFLOWS_BY_PROJECTID', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_ALL_PROJECTS', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('TRANSLATECRON', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('NEXTCRON', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_SCHEDULE_PAGE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_ASSETS_PAGE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ADD_ASSET', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('UPDATE_ASSET', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ADD_QUEUE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('UPDATE_QUEUE', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_QUEUE_ITEMS_INPROGRERSS', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('CHECKlICENSE', (...args) => { args[args.length - 1].apply(this, args); }); 
        this.socket.on('IS_USER_SUPERADMIN', (...args) => { args[args.length - 1].apply(this, args); }); 
        this.socket.on('SHOW_PERMISSIONS', (...args) => { args[args.length - 1].apply(this, args); }); 
        this.socket.on('GET_ALL', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('GET_WITH_PROJECTION', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('DELETE_QUEUE_ITEM', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('ADD_FORM', (...args) => { args[args.length - 1].apply(this, args); });
        this.socket.on('AUTH', (...args) => {
            this.jwt = args[1];
            this.user = args[2];
            if (this.user) {
                $rootScope.$broadcast('signin', this.user);
            }
            else {
                $rootScope.$broadcast('signout', null);
            }
            if (typeof args[args.length - 1] === "function") {
                args[args.length - 1].apply(this, args);
            }
        });
    }
 

    settoken(jwt:string) {
        return new Promise<any>(async (resolve, reject) => {
            try {
                this.socket.emit('AUTHJWT', jwt, function (err, token, user) {
                    if(err) { return reject(err); }
                    user.token = token;
                    resolve(user);
                });
            } catch (err) {
                reject(err);
            }
        });
    }

  
}


class socketuserrole {
    name: string;
    _id: string;
}
class socketuser {
    _id: string;
    _type: string;
    _acl: any[];
    roles: socketuserrole[];
    name: string;
    username: string;
    _created: Date;
    _createdby: string;
    _createdbyid: string;
    _modified: Date;
    _modifiedby: string;
    _modifiedbyid: string;
}

class socketServiceFactory {
    static $inject = ['$rootScope', 'ipCookie','toastr'];
    signedin: boolean;
    user: socketuser |null;
    workflows: socketServiceClass;
    workflowschedules :socketServiceClass;
    entities: socketServiceClass;
    users: socketServiceClass;
    namespace:socketServiceClass;
    schedule:socketServiceClass;
    generic:GenericServiceClass;
    constructor(
        private $rootScope,
        private ipCookie: any,
         toastr22 : any
    ) {
        this.signedin = false;
        var me = this;
       this.namespace = new socketServiceClass('',this.jwt(),$rootScope);
      //this.workflows = new socketServiceClass('workflows', this.jwt(), $rootScope),
        //this.entities = new socketServiceClass('entities', this.jwt(), $rootScope),
           //this.users = new socketServiceClass('users', this.jwt(), $rootScope),
        //this.workflowschedules = new socketServiceClass('workflowschedules' ,this.jwt() , $rootScope)
        // if(this.ipCookie('user')!= {} && this.ipCookie('user') != null){
        if(true){
            this.user = this.ipCookie('user');
        }
            $rootScope.$on('signin', function (event, data) {
                if (event) { }
                me.$rootScope.user = data;
                me.signedin = true;
                me.user = data;
                me.ipCookie('user' , data)
                //this.user = this.ipCookie('user');
            });
        $rootScope.$on('signout', function (event, data) {
            if (event && data) { }
            $rootScope.user = null;
            me.signedin = false;
            me.user = null;
            me.ipCookie('user' ,{});
        });
    }
    jwt(): string {
        return this.ipCookie('jwt');
    }

    setUser() {
        this.user = this.ipCookie('user');
    }

    clearUser() {
        this.ipCookie('user' ,{});
    }


    onSignedin(callback) {
        var me = this;
        if (this.signedin) {
            callback(this.user);
            return;
        }
        var cleanup = this.$rootScope.$on('signin', function (event, data) {
            if (event && data) { }
            cleanup();
            callback(me.user);
        });

        return cleanup;

    }
}

class base {
    _id: string;
    _type: string;
    _acl: any[];
    name: string;
    _created: Date;
    _createdby: string;
    _createdbyid: string;
    _modified: Date;
    _modifiedby: string;
    _modifiedbyid: string;

    static assign<T>(o:T): T {
        return Object.assign(new base(), o);;
    }

    removeace(_id:string) {
        for (var i = 0; i < this._acl.length; i++) {
            if (this._acl[i]._id == _id) {
                this._acl.splice(i, 1);
                //this._acl = this._acl.splice(index, 1);
            }
        }
    }
    aceexists(_id:string) {
        for (var i = 0; i < this._acl.length; i++) {
            if (this._acl[i]._id == _id) {
                return true;
            }
        }
        return false;
    }
    addace(_id:string, name:string) {
        var ace = {
            deny: false,
            _id: _id,
            name: name,
            rights: "//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8="
        } 
        this._acl.push(ace);
    }
    isBitSet(base64: string, bit: number): boolean {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        return (currentValue & mask) != 0;
    }
    setBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue | mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
  
    unsetBit(base64: string, bit: number) {
        bit--;
        var buf = this._base64ToArrayBuffer(base64);
        var view = new Uint8Array(buf);
        var octet = Math.floor(bit / 8);
        var currentValue = view[octet];
        var _bit = (bit % 8);
        var mask = Math.pow(2, _bit);
        var newValue = currentValue &= ~mask;
        view[octet] = newValue;
        return this._arrayBufferToBase64(view);
    }
    toogleBit(a: any, bit: number) { 
        if (this.isBitSet(a.rights, bit)) {
            a.rights = this.unsetBit(a.rights, bit);
        } else {
            a.rights = this.setBit(a.rights, bit);
        }
        var buf2 = this._base64ToArrayBuffer(a.rights);
        var view2 = new Uint8Array(buf2); 
        console.log(view2)
    }
    _base64ToArrayBuffer(string_base64): ArrayBuffer {
        var binary_string = window.atob(string_base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            //var ascii = string_base64.charCodeAt(i);
            var ascii = binary_string.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes.buffer;
    }
    _arrayBufferToBase64(array_buffer): string {
        var binary = '';
        var bytes = new Uint8Array(array_buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary);
    }
}
//new acl function to set permissions
function add_acl(model : any , _id:string, name:string,permissions:Array<any>) {
    var ace = {
        deny: false,
        _id: _id,
        name: name,
        permissions:permissions,
        rights: "//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8="
    } 
    model._acl.push(ace);
}
function setBit(base64: string, bit: number) {
    bit--;
    var buf = this._base64ToArrayBuffer(base64);
    var view = new Uint8Array(buf);
    var octet = Math.floor(bit / 8);
    var currentValue = view[octet];
    var _bit = (bit % 8);
    var mask = Math.pow(2, _bit);
    var newValue = currentValue | mask;
    view[octet] = newValue;
    return this._arrayBufferToBase64(view);
}
function addace(model : any , _id:string, name:string) {
    var ace = {
        deny: false,
        _id: _id,
        name: name,

        rights: "//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8="
    } 
    model._acl.push(ace);
}
